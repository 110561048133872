import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import { NormalPadding } from "styles/GlobalStyles"

const Page = ({ data }) => {
    var page = data.nodePage
    console.log('in page.js (#4jf0fnd). page = ', page)
    // console.log('in page.js (#4jf0fnd). page = ', page)

    // return ('test')

    return (
        <Layout>
            <NormalPadding>
                <SEO title={page.title} />
                
                <main>
                    <h2>{page.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: page.body.processed }} />
                </main>
            </NormalPadding>
        </Layout>
    )
}

export default Page

export const query = graphql`
query FindAllPages ($nid: Int) {
    nodePage(drupal_internal__nid: {eq: $nid}) {
      id
      title
      fields {
        slug
      }
      body {
        processed
      }
    }
}  
`